import React, { Component } from 'react';
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import CheckOut from './CheckOut';
import Success from './Success';
import Failed from './Failed';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="checkout/:storename/:invoiceid/" element={<CheckOut />} />
        <Route path="success/:storename/:invoiceid/" element={<Success />} />
        <Route path="failed/:storename/:invoiceid/" element={<Failed />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
