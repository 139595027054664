import React, { useState, useEffect } from "react";
import { db } from "./firebase";
import { useParams } from "react-router-dom";
import { getDoc, doc } from "firebase/firestore"
import NumberFormat from 'react-number-format';

import './App.css';



import bg from './assets/bg.jpg';

import mastercardlogo from './assets/mastercard.png';
import visalogo from './assets/visa.png';
import amexlogo from './assets/amex.png';
import bkashlogo from './assets/bkash.png';
import dbbllogo from './assets/dbbl.png';
import nagadlogo from './assets/nagad.png';
import rocketlogo from './assets/rocket.png';
import upaylogo from './assets/upay.png';
import wdpaymentlogo from './assets/wdpay.png';

import { GrClose } from "react-icons/gr";


const CheckOut = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [isPaymentLoading, setPaymentIsLoading] = useState(false);
    const [closeDialog, setCloseDialog] = useState(false);
    const params = useParams();
    const storename = params.storename;
    const invoiceid = params.invoiceid;

    const invoicepath = "stores/"+storename+"/invoices"

    const [storeData, setStoreData] = useState({});
    const [invoiceData, setInvoiceData] = useState({});


    const [paynowbtntext, setPayNowBtnText] = useState("SELECT A PAYMENT METHOD");

    const [paymentCurrency, setPaymentCurrency] = useState("bdt");
    const [paymentMethod, setPaymentMethod] = useState("cards");
    const [intpaymentMethod, setIntPaymentMethod] = useState("cards");
    const [paymentMethodName, setPaymentMethodName] = useState("");

    const [invoiceamount, setInvoiceamount] = useState();
    const [invoicechargeamount, setInvoiceChargeamount] = useState(0);

    async function getDatabase() {
        const storedocRef = doc(db, "stores", storename);
        const storedocSnap = await getDoc(storedocRef);

        if (storedocSnap.exists()) {
            setStoreData(storedocSnap.data());
            document.title = "Make Payment - " +storedocSnap.data().storename;
                if(storedocSnap.data().colorscheme){
            }
        }

        const invoicedocRef = doc(db, invoicepath, invoiceid);
        const invoicedocSnap = await getDoc(invoicedocRef);

        if (invoicedocSnap.exists()) {
            setInvoiceData(invoicedocSnap.data());
            if(!invoiceamount){
                setInvoiceamount(invoicedocSnap.data().amount);
            }
            setTimeout(() => {
                setIsLoading(false);
            }, 500);
        }
    }

    useEffect(() => { 
        getDatabase(); 
    }, [invoiceid]);

    const CurrentPaymentMethod = (props) => {
        const virtualcardmethodiconclass = "h-6 w-auto grayscale opacity-90";
        if(paymentMethodName === "mastercard"){
            return(
                <img src={mastercardlogo} className={virtualcardmethodiconclass} alt={paymentMethodName}/>
            );
        } else if(paymentMethodName === "visa"){
            return(
                <img src={visalogo} className={virtualcardmethodiconclass} alt={paymentMethodName}/>
            );
        } else if(paymentMethodName === "amex"){
            return(
                <img src={amexlogo} className={virtualcardmethodiconclass} alt={paymentMethodName}/>
            );
        } else if(paymentMethodName === "nexus"){
            return(
                <img src={dbbllogo} className={virtualcardmethodiconclass} alt={paymentMethodName}/>
            );
        } else if(paymentMethodName === "bkash"){
            return(
                <img src={bkashlogo} className={virtualcardmethodiconclass} alt={paymentMethodName}/>
            );
        } else if(paymentMethodName === "nagad"){
            return(
                <img src={nagadlogo} className={virtualcardmethodiconclass} alt={paymentMethodName}/>
            );
        } else if(paymentMethodName === "rocket"){
            return(
                <img src={rocketlogo} className={virtualcardmethodiconclass} alt={paymentMethodName}/>
            );
        } else if(paymentMethodName === "upay"){
            return(
                <img src={upaylogo} className={virtualcardmethodiconclass} alt={paymentMethodName}/>
            );
        } else {
            return(
                <img src={wdpaymentlogo} className={virtualcardmethodiconclass} alt="White Digit Payment"/>
            );
        }
    }

   function switchPaymentMethodName(getpaymentmethodname) { 
        setPaymentMethodName(getpaymentmethodname);
        setPayNowBtnText("PAY NOW");
        if(storeData.txncharge === true){
            var txncharge = 0;
            var txnchargeamount = 0;
            var txnchargeamountrounded = 0;
            if(getpaymentmethodname === "amex"){
                txncharge = 0.035;
                txnchargeamount = parseFloat(invoiceData.amount) * parseFloat(txncharge);
            } else {
                txncharge = 0.025;
                txnchargeamount = parseFloat(invoiceData.amount) * parseFloat(txncharge);
            }
            if(txnchargeamount < 5){
                txnchargeamountrounded = 5;
            } else {
                txnchargeamountrounded = txnchargeamount;
            }
            setInvoiceChargeamount(Math.ceil(txnchargeamountrounded));
            setInvoiceamount(Math.ceil(txnchargeamountrounded + parseFloat(invoiceData.amount)));
        }
    }

    function switchCurrency(currency){
        setPaymentCurrency(currency);
        if(currency === "usd"){
            setInvoiceamount(invoiceData.usdamount);
            setInvoiceChargeamount(0);
        } else {
            switchPaymentMethodName(paymentMethodName)
        }
    }

    function HandlePayNow(){
        setPayNowBtnText("PLEASE WAIT...");
        setTimeout(() => {
            setPaymentIsLoading(true);
        }, 300);
    }

    const switcherbtnclass = "rounded-l-lg px-3 py-2 md:px-6 md:py-3 font-medium text-xs md:text-md leading-tight uppercase focus:outline-none focus:ring-0 transition duration-150 ease-in-out font-bold";
    const methodswitcherclass = "w-1/2 bg-white border-b rounded-t-lg -mb-px text-xs md:text-sm font-bold p-3";
    const methodswitcherclassactive = "border border-b-0 text-blue-600";
    const paymentmethodcardclass = "font-bold flex text-xs md:text-md items-center justify-center border w-full p-4 md:px-8 md:py-5 rounded-lg cursor-pointer hover:border-blue-600 transition";
    const paymentmethodcardcolclass= "p-2 w-full md:w-1/2";
    const paymentmethodactivecardclass = "bg-blue-100 text-blue-600 border-blue-600 transition";
    const paymentmethodcardimageclass = "h-5 w-auto mr-3";
    const tablecolitemclass = "text-xs text-gray-500 flex uppercase py-2 font-medium";
    const tablecolnameclass = "grow mr-2";
    const tablecolvalueclass = "text-gray-900 text-right font-bold";

    return (
        <div className={`WhiteDigitPayment bg-blue-600 bg-cover bg-center`} style={{ backgroundImage: `url(${bg})` }}>
            <div className="min-h-screen flex items-center justify-center backdrop backdrop-blur-3xl bg-gray-700/20">
                <div className="w-60rem max-w-11/12 md:m-4">
                    <div className="md:flex bg-white md:rounded-xl md:shadow-xl overflow-hidden relative">
                        {isLoading &&
                            <div className="md:rounded-xl absolute left-0 top-0 w-full h-screen md:h-full flex items-center justify-center  backdrop backdrop-blur-md bg-gray-50/40 z-50">
                                <svg className={`animate-spin h-24 w-24 text-blue-600`} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                </svg>
                            </div>
                        }
                        {closeDialog &&
                            <div className="md:rounded-xl absolute left-0 top-0 w-full p-4 h-full flex flex-wrap flex-col items-center justify-center  backdrop backdrop-blur-md bg-white/60 z-50">
                                <h2 className="uppercase font-bold text-xl text-gray-600 mb-12 text-red-600 text-center">Cancel payment and return to the store?</h2>
                                <p className="flex justify-center">
                                    <a className="mx-2 text-center border border-red-600 text-red-600 font-bold uppercase px-6 py-3 rounded-lg transition hover:bg-red-600 hover:text-white text-xs" href={storeData.cancelurl}>Yes, Cancel Payment</a>
                                    <button onClick={() => setCloseDialog(false)} className="mx-2 text-center border border-green-600 text-green-600 font-bold uppercase px-6 py-3 rounded-lg transition hover:bg-green-600 hover:text-white text-xs" >Continue to the Payment</button>
                                </p>
                            </div>
                        }

                        {invoiceData.paymentstatus === "paid" ?
                            <div className="h-80 w-full flex items-center justify-center text-gray-500 text-lg">
                                You've already paid for this invoice.
                            </div>
                        :
                        <>
                            <div className="md:w-8/12 py-10 relative">
                                {isPaymentLoading &&
                                    <div className="md:rounded-xl absolute left-0 top-0 w-full h-screen md:h-full flex flex-col items-center justify-center  backdrop backdrop-blur-md bg-gray-50/40 z-50">
                                        <svg className={`animate-spin h-16 w-16 text-blue-600`} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                        </svg>
                                        <h2 className="text-blue-600 font-bold text-md mt-6 animate-pulse">TRANSFERRING TO BANK...</h2>
                                    </div>
                                }
                                <div className="flex items-center mb-10 px-8">
                                    <div className="grow">
                                        <img className="h-10 md:h-12 w-auto" src={storeData.storelogo} alt={storeData.storename}/>
                                    </div>
                                    <div className="">
                                        <div className="inline-flex" role="group">
                                            <button type="button" onClick={() => switchCurrency("bdt")} className={`${switcherbtnclass} ${paymentCurrency === "bdt" ? "bg-blue-600 text-white" : "bg-gray-200 text-gray-500"}`}>BDT</button>
                                            {storeData.usdpayment && invoiceData.usdamount > 0 ?
                                                <button type="button" onClick={() => switchCurrency("usd")} className={`${switcherbtnclass} rounded-r-lg rounded-l-none ${paymentCurrency === "usd" ? "bg-blue-600 text-white" : "bg-gray-200 text-gray-500"}`}>USD</button>
                                            :
                                                <button type="button" className={`${switcherbtnclass} rounded-r-lg rounded-l-none cursor-not-allowed bg-gray-200 text-gray-500`} disabled>USD</button>
                                            }
                                            
                                        </div>
                                    </div>
                                </div>
                                <div className="px-8 mb-10">
                                    <div className={`uppercase font-medium text-sm text-blue-600 bg-blue-100 p-3 text-center rounded-lg border border-blue-300`}>
                                            <div className="flex flex-wrap items-center justify-center font-bold text-lg">YOU'RE PAYING<span className="mx-2">{paymentCurrency} <NumberFormat thousandSeparator={true} thousandsGroupStyle="lakh" value={invoiceamount} displayType={'text'}/></span>ONLY</div>
                                        {invoicechargeamount > 0 && 
                                            <p className="text-xxs mt-1 opacity-80">(Bdt {invoicechargeamount} taka payment gateway fee added)</p>
                                        }
                                    </div>
                                </div>
                                {paymentCurrency === "usd" ?
                                <>
                                    <div>
                                        <div className="flex items-center border-b px-8">
                                            <button type="button" onClick={() => setIntPaymentMethod("cards")} className={`${methodswitcherclass} ${intpaymentMethod === "cards" ? methodswitcherclassactive : "text-gray-600"}`}><span className="hidden md:inline">CREDIT/DEBIT </span>CARDS</button>
                                            <button type="button" onClick={() => setIntPaymentMethod("mfs")} className={`${methodswitcherclass} ${intpaymentMethod === "mfs" ? methodswitcherclassactive : "text-gray-600"}`}><span className="hidden md:inline">MOBILE </span>WALLETS</button>
                                        </div>
                                    </div>
                                    <div className="mt-10">
                                        {intpaymentMethod === "cards" ?
                                            <div className="px-8">
                                                Not supported yet.
                                            </div>
                                        :
                                            <div className="px-8">
                                                Not supported yet.
                                            </div>
                                        }
                                    </div>
                                </>
                                :
                                <>
                                    <div className="flex items-center border-b px-8">
                                        <button type="button" onClick={() => setPaymentMethod("cards")} className={`${methodswitcherclass} ${paymentMethod === "cards" ? methodswitcherclassactive : "text-gray-600"}`}><span className="hidden md:inline">CREDIT/DEBIT </span>CARDS</button>
                                        <button type="button" onClick={() => setPaymentMethod("mfs")} className={`${methodswitcherclass} ${paymentMethod === "mfs" ? methodswitcherclassactive : "text-gray-600"}`}><span className="hidden md:inline">MOBILE </span>WALLETS</button>
                                    </div>
                                    <div className="mt-10">
                                        {paymentMethod === "cards" ?
                                            <div className="px-6 flex flex-wrap">
                                                <div className={paymentmethodcardcolclass}>
                                                    <div onClick={() => switchPaymentMethodName("mastercard")} className={`${paymentmethodcardclass} ${paymentMethodName === "mastercard" ? paymentmethodactivecardclass : "text-gray-600 hover:text-blue-600"}`}><img className={paymentmethodcardimageclass} src={mastercardlogo} alt="Mastercard"/>MASTERCARD</div>
                                                </div>
                                                <div className={paymentmethodcardcolclass}>
                                                    <div onClick={() => switchPaymentMethodName("visa")} className={`${paymentmethodcardclass} ${paymentMethodName === "visa" ? paymentmethodactivecardclass : "text-gray-600 hover:text-blue-600"}`}><img className={paymentmethodcardimageclass} src={visalogo} alt="Visa"/>VISA</div>
                                                </div>
                                                <div className={paymentmethodcardcolclass}>
                                                    <div onClick={() => switchPaymentMethodName("amex")} className={`${paymentmethodcardclass} ${paymentMethodName === "amex" ? paymentmethodactivecardclass : "text-gray-600 hover:text-blue-600"}`}><img className={paymentmethodcardimageclass} src={amexlogo} alt="American Express"/>AMEX</div>
                                                </div>
                                                <div className={paymentmethodcardcolclass}>
                                                    <div onClick={() => switchPaymentMethodName("nexus")} className={`${paymentmethodcardclass} ${paymentMethodName === "nexus" ? paymentmethodactivecardclass : "text-gray-600 hover:text-blue-600"}`}><img className={paymentmethodcardimageclass} src={dbbllogo} alt="Nexus Pay"/>NEXUS</div>
                                                </div>
                                            </div>
                                            :
                                            <div className="px-6 flex flex-wrap">
                                                <div className={paymentmethodcardcolclass}>
                                                    <div onClick={() => switchPaymentMethodName("bkash")} className={`${paymentmethodcardclass} ${paymentMethodName === "bkash" ? paymentmethodactivecardclass : "text-gray-600 hover:text-blue-600"}`}><img className={paymentmethodcardimageclass} src={bkashlogo} alt="bKash"/>BKASH</div>
                                                </div>
                                                <div className={paymentmethodcardcolclass}>
                                                    <div onClick={() => switchPaymentMethodName("nagad")} className={`${paymentmethodcardclass} ${paymentMethodName === "nagad" ? paymentmethodactivecardclass : "text-gray-600 hover:text-blue-600"}`}><img className={paymentmethodcardimageclass} src={nagadlogo} alt="Nagad"/>NAGAD</div>
                                                </div>
                                                <div className={paymentmethodcardcolclass}>
                                                    <div onClick={() => switchPaymentMethodName("rocket")} className={`${paymentmethodcardclass} ${paymentMethodName === "rocket" ? paymentmethodactivecardclass : "text-gray-600 hover:text-blubluee-600"}`}><img className={paymentmethodcardimageclass} src={rocketlogo} alt="Rocket"/>ROCKET</div>
                                                </div>
                                                <div className={paymentmethodcardcolclass}>
                                                    <div onClick={() => switchPaymentMethodName("upay")} className={`${paymentmethodcardclass} ${paymentMethodName === "upay" ? paymentmethodactivecardclass : "text-gray-600 hover:text-blue-600"}`}><img className={paymentmethodcardimageclass} src={upaylogo} alt="UPay"/>UPAY</div>
                                                </div>
                                            </div>
                                        
                                        }
                                    </div>
                                    <div className="px-8 mt-8">
                                        <form action="https://payments.whitedigit.com/checkout.php" method="POST">
                                        <input type="hidden" name="invoiceid" value={invoiceid}/>
                                        <input type="hidden" name="storename" value={storename}/>
                                        <input type="hidden" name="targetmethod" value={paymentMethodName}/>
                                        {paymentMethodName ?
                                            <button onClick={() => HandlePayNow()} className={`bg-blue-600 bg-gradient-to-r from-blue-600 via-blue-700 to-blue-600 hover:bg-blue-700 hover:from-blue-700 hover:via-blue-600 hover:to-blue-700 transition-all w-full text-white font-bold uppercase text-xl p-4 rounded-lg shadow-xl`}>{paynowbtntext}</button>
                                        :
                                            <button className={`border-blue-600 transition-all w-full text-blue-600 border opacity-50 font-bold uppercase text-md p-4 rounded-lg cursor-not-allowed`} disabled>{paynowbtntext}</button>
                                        }
                                        
                                        </form>
                                    </div>
                                </>
                                }
                            </div>
                            <div className="w-full md:w-4/12 bg-gray-100 relative">
                                <div className="absolute hidden md:block right-4 top-3">
                                    <button onClick={() => setCloseDialog(true)}>
                                        <GrClose className="text-sm text-gray-100"/>
                                    </button>
                                </div>
                                <div className="py-10 px-8">
                                    <div className="mx-4 bg-white bg-gradient-to-r from-gray-100 to-gray-300 p-4 rounded-lg shadow-lg -mb-12 z-40 relative hidden md:block">
                                        <div className="flex">
                                            <div className="grow">
                                                <img className="h-6 w-auto grayscale opacity-90" src={storeData.storelogo} alt={storeData.storename}/>
                                            </div>
                                            <div>
                                                {paymentCurrency === "bdt" &&
                                                    <CurrentPaymentMethod/>
                                                }
                                            </div>
                                        </div>
                                        <div className="mt-14">
                                            <h3 className="uppercase text-sm font-mono font-bold tracking-widest text-gray-500 opacity-70">{invoiceData.customer_name}</h3>
                                        </div>
                                    </div>
                                    <div className="bg-gray-50 px-4 py-6 md:pt-20 rounded-lg border">
                                        <div className="">
                                            <div className={tablecolitemclass}>
                                                <span className={tablecolnameclass}>Paying to</span>
                                                <span className={tablecolvalueclass}>{storeData.storename}</span>
                                            </div>
                                            <div className={tablecolitemclass}>
                                                <span className={tablecolnameclass}>Invoice</span>
                                                <span className={tablecolvalueclass}>#{storeData.storeprefix}{invoiceid}</span>
                                            </div>
                                            <div className={tablecolitemclass}>
                                                <span className={tablecolnameclass}>Product</span>
                                                <span className={tablecolvalueclass}>{invoiceData.product_name}</span>
                                            </div>
                                            <div className={tablecolitemclass}>
                                                <span className={tablecolnameclass}>Price</span>
                                                {paymentCurrency === "bdt" ?
                                                    <span className={tablecolvalueclass}>{paymentCurrency} <NumberFormat thousandSeparator={true} thousandsGroupStyle="lakh" value={invoiceData.amount} displayType={'text'}/></span>
                                                :
                                                    <span className={tablecolvalueclass}>{paymentCurrency} <NumberFormat thousandSeparator={true} thousandsGroupStyle="lakh" value={invoiceamount} displayType={'text'}/></span>
                                                }
                                                
                                            </div>
                                            {invoicechargeamount > 0 &&
                                            <div className={tablecolitemclass}>
                                                <span className={tablecolnameclass}>Gatway Fee</span>
                                                <span className={tablecolvalueclass}>{paymentCurrency} <NumberFormat thousandSeparator={true} thousandsGroupStyle="lakh" value={invoicechargeamount} displayType={'text'}/></span>
                                            </div>
                                            }
                                        </div>
                                        <div className="-mx-4 my-6 relative">
                                            <span className="absolute -top-3 -left-3 w-6 h-6 bg-gray-100 block rounded-full border-t border-r rotate-45"></span>
                                            <span className="border-t border-dashed h-px block"></span>
                                            <span className="absolute -top-3 -right-3 w-6 h-6 bg-gray-100 block rounded-full border-t border-l -rotate-45"></span>
                                        </div>
                                        <div className="uppercase relative">
                                            <p className="text-xs font-medium text-gray-500 mb-1">You will be charged</p>
                                            <h4 className="text-md text-gray-900 font-medium">{paymentCurrency} <span className="font-bold text-3xl"><NumberFormat thousandSeparator={true} thousandsGroupStyle="lakh" value={invoiceamount} displayType={'text'}/></span></h4>
                                            <span className="absolute -top-5 right-0 font-bold text-8xl text-gray-600 opacity-20">
                                                { paymentCurrency === "bdt" ?
                                                    <>৳</>
                                                :
                                                    <>$</>
                                                }
                                            </span>
                                        </div>
                                    </div>
                                    <div className="block md:hidden">
                                        <a className="mt-6 w-full text-center border border-red-600 text-red-600 font-bold uppercase px-6 py-3 rounded-lg transition hover:bg-red-600 hover:text-white text-xs block" href={storeData.cancelurl}>
                                            CANCEL PAYMENT
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </>
                        }
                    </div>
                    <div className="text-center mt-10 mb-10 md:mb-0 text-xxs opacity-7 text-gray-100">POWERED BY <a className="font-bold" href="https://whitedigit.com" target="_blank">WHITE DIGIT TECHNOLOGIES</a></div>
                </div>
            </div>
        </div>
        
    );
}

export default CheckOut;