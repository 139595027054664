import { initializeApp } from "firebase/app";
import { getFirestore } from 'firebase/firestore';

const { initializeAppCheck, ReCaptchaV3Provider } = require("firebase/app-check");


const firebaseConfig = {
    apiKey: "AIzaSyCnofOrAk1tXyoZwfy-3aa5YbhedCgTmwQ",
    authDomain: "wd-payments.firebaseapp.com",
    projectId: "wd-payments",
    storageBucket: "wd-payments.appspot.com",
    messagingSenderId: "246929308935",
    appId: "1:246929308935:web:2c222a9daea9bbcb9359a2",
    measurementId: "G-6PGSTR0T6M"
};

const app = initializeApp(firebaseConfig);

export const db = getFirestore(app);

if(process.env.REACT_APP_APP_CHECK_ID){
    const appCheck = initializeAppCheck(app, {
        provider: new ReCaptchaV3Provider("6LfCecIhAAAAAICjt_iDsx_5RFP5vUp7l_uWH1Ci"),

        isTokenAutoRefreshEnabled: true
    });
}



export default app;