import React, { useState, useEffect } from "react";
import { db } from "./firebase";
import { useParams } from "react-router-dom";
import { getDoc, doc } from "firebase/firestore";
import NumberFormat from "react-number-format";

import "./App.css";

import bg from "./assets/bg.jpg";

const Failed = () => {
  const [isLoading, setIsLoading] = useState(true);
  const params = useParams();
  const storename = params.storename;
  const invoiceid = params.invoiceid;

  const invoicepath = "stores/" + storename + "/invoices";

  const [storeData, setStoreData] = useState({});
  const [invoiceData, setInvoiceData] = useState({});

  const [invoiceamount, setInvoiceamount] = useState();

  async function getDatabase() {
    const storedocRef = doc(db, "stores", storename);
    const storedocSnap = await getDoc(storedocRef);

    if (storedocSnap.exists()) {
      setStoreData(storedocSnap.data());
      document.title = "Payment Failed - " + storedocSnap.data().storename;
      if (storedocSnap.data().colorscheme) {
      }
    }

    const invoicedocRef = doc(db, invoicepath, invoiceid);
    const invoicedocSnap = await getDoc(invoicedocRef);

    if (invoicedocSnap.exists()) {
      setInvoiceData(invoicedocSnap.data());
      if (!invoiceamount) {
        setInvoiceamount(invoicedocSnap.data().amount);
      }
      setTimeout(() => {
        setIsLoading(false);
      }, 500);
    }
  }

  useEffect(() => {
    getDatabase();
  }, [invoiceid]);

  return (
    <div
      className={`WhiteDigitPayment bg-blue-600 bg-cover bg-center`}
      style={{ backgroundImage: `url(${bg})` }}
    >
      <div className="min-h-screen flex items-center justify-center backdrop backdrop-blur-3xl bg-gray-700/20">
        <div className="w-11/12 sm:w-30rem md:m-4">
          <div className="md:flex bg-white rounded-xl shadow-xl overflow-hidden relative">
            {isLoading && (
              <div className="md:rounded-xl absolute left-0 top-0 w-full h-screen md:h-full flex items-center justify-center  backdrop backdrop-blur-md bg-gray-50/40 z-50">
                <svg
                  className={`animate-spin h-24 w-24 text-blue-600`}
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  ></path>
                </svg>
              </div>
            )}
            <div className="md:w-full py-5 relative">
              <div className="flex items-center pb-5 px-8 border-b">
                <div className="grow">
                  <img
                    className="h-10 md:h-12 w-auto"
                    src={storeData.storelogo}
                    alt={storeData.storename}
                  />
                </div>
                <div className="">
                  <div className="inline-flex" role="group">
                    <span className="font-bold text-blue-600 text-3xl">
                      ৳
                      <NumberFormat
                        thousandSeparator={true}
                        thousandsGroupStyle="lakh"
                        value={invoiceamount}
                        displayType={"text"}
                      />
                    </span>
                  </div>
                </div>
              </div>
              <div className="flex flex-col items-center px-8 py-20 justify-center text-center">
                <h3 className="font-bold text-yellow-700 text-md mb-3 tracking-widest">
                  PAYMENT FAILED
                </h3>
                <h2 className="font-bold text-5xl text-yellow-600">
                  SOMETHING WRONG!
                </h2>
              </div>
            </div>
          </div>
          <div className="text-center mt-10 mb-10 md:mb-0 text-xxs opacity-7 text-gray-100">
            POWERED BY{" "}
            <a
              className="font-bold"
              href="https://whitedigit.com"
              target="_blank"
            >
              WHITE DIGIT TECHNOLOGIES
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Failed;
